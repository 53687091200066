import {Figure} from '@mindfulness/cms';
import {FC, useContext, useMemo} from 'react';

import {
  BackgroundImage,
  ButtonLink,
  Context,
  ModalContext,
  SmallModal,
} from '../global';

import {Box, Stack} from '../layout';
import {Title1} from '../typography';
import {Button} from '../forms';

const defaultModalImage: Figure = {
  _type: 'figure',
  image: {
    _type: 'image',
    asset: {
      _ref: 'image-ed691c75c71a31d72cf1e0d34685cbd5eafebe5a-720x900-jpg',
      _type: 'reference',
    },
  },
};

export const AddToCartModal: FC = () => {
  const {activeProps, closeModal} = useContext(ModalContext);
  const {data} = useContext(Context);
  const props = useMemo(() => activeProps.action, [activeProps.action]);
  const image = useMemo(
      () =>
        data?.action?.modal?.image ||
      props?.action?.modal?.image ||
      defaultModalImage,
      [data?.action?.modal?.image, props?.action?.modal?.image, defaultModalImage],
  );

  return (
    <SmallModal
      name="addToCart"
      Top={image ? <BackgroundImage image={image} /> : undefined}
      Bottom={() => (
        <Box paddingX={40}>
          <Stack direction="vertical" space={16}>
            <Title1 as="h2">Added to your cart!</Title1>

            <ButtonLink id="Checkout" href="/checkout" wide>
              Checkout
            </ButtonLink>

            <Button
              type="button"
              onClick={() =>
                closeModal?.({
                  name: 'addToCart',
                })
              }
              id="ContinueBrowsing"
              variant="secondary"
              wide
            >
              Continue Browsing
            </Button>
          </Stack>
        </Box>
      )}
    />
  );
};
